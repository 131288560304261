import { toast } from "react-toastify";
import {
  raiseDispute,
  loadAllDisputes,
  loadResolvedDisputes,
  deleteDispute,
  updateDispute,
  deleteDisputeDocument
} from "../../../services/admin/DisputeService";
import {
  LOAD_DISPUTES,
  LOAD_DISPUTES_ERROR,
  LOAD_RESOLVED_DISPUTES,
  LOAD_RESOLVED_DISPUTES_ERROR,
  CREATE_DISPUTE_LOADING,
  CLEAR_CREATE_DISPUTE,
  CREATE_DISPUTE_CODE_ERROR,
  CREATE_DISPUTE_SUCCESS,
  CREATE_DISPUTE_FAILED,
  DELETE_DISPUTE_LOADING,
  CLEAR_DELETE_DISPUTE,
  DELETE_DISPUTE_CODE_ERROR,
  DELETE_DISPUTE_SUCCESS,
  DELETE_DISPUTE_FAILED,
  EDIT_DISPUTE_LOADING,
  CLEAR_EDIT_DISPUTE,
  EDIT_DISPUTE_CODE_ERROR,
  EDIT_DISPUTE_SUCCESS,
  EDIT_DISPUTE_FAILED,
} from "../../actiontypes/admin/Dispute";

export const raiseDisputeAction = (credentials) => {
  return (dispatch) => {
    let creds = {};
    creds.tov_id = credentials.id;
    dispatch({ type: CLEAR_CREATE_DISPUTE });
    dispatch({ type: CREATE_DISPUTE_LOADING });
    raiseDispute(creds).then(
      (res) => {
        if (res.hasOwnProperty("success") && res.success === true) {
          dispatch({ type: CREATE_DISPUTE_SUCCESS, res });
          toast.success(res.response.message);
        } else if (res.hasOwnProperty("success") && res.success === false) {
          dispatch({ type: CREATE_DISPUTE_FAILED, res });
          toast.error(res.response.message);
        }
      },
      (error) => {
        console.log(error);
        dispatch({ type: CREATE_DISPUTE_CODE_ERROR, error });
      }
    );
  };
};

export const loadDisputeReports = () => {
  return (dispatch) => {
    loadAllDisputes().then(
      (res) => {
        console.log(res);
        dispatch({ type: LOAD_DISPUTES, res });
      },
      (error) => {
        console.log(error);
        dispatch({ type: LOAD_DISPUTES_ERROR, error });
      }
    );
  };
};

export const loadResolvedDisputeReports = () => {
  return (dispatch) => {
    loadResolvedDisputes().then(
      (res) => {
        dispatch({ type: LOAD_RESOLVED_DISPUTES, res });
      },
      (error) => {
        dispatch({ type: LOAD_RESOLVED_DISPUTES_ERROR, error });
      }
    );
  };
};

export const updateDisputeAction = (credentials) => {
  return (dispatch) => {
    dispatch({ type: CLEAR_EDIT_DISPUTE });
    dispatch({ type: EDIT_DISPUTE_LOADING });
    updateDispute(credentials).then(
      (res) => {
        if (res.hasOwnProperty("success") && res.success === true) {
          dispatch({ type: EDIT_DISPUTE_SUCCESS, res });
          toast.success(res.response.message);
        } else if (res.hasOwnProperty("success") && res.success === false) {
          dispatch({ type: EDIT_DISPUTE_FAILED, res });
          toast.error(res.response.message);
        }
      },
      (error) => {
        console.log(error);
        dispatch({ type: EDIT_DISPUTE_CODE_ERROR, error });
      }
    );
  };
};

export const deleteDisputeAction = (credentials) => {
  return (dispatch) => {
    dispatch({ type: CLEAR_DELETE_DISPUTE });
    dispatch({ type: DELETE_DISPUTE_LOADING });
    deleteDispute(credentials).then(
      (res) => {
        if (res.hasOwnProperty("success") && res.success === true) {
          dispatch({ type: DELETE_DISPUTE_SUCCESS, res });
          toast.success(res.response.message);
        } else if (res.hasOwnProperty("success") && res.success === false) {
          dispatch({ type: DELETE_DISPUTE_FAILED, res });
          toast.error(res.response.message);
        }
      },
      (error) => {
        console.log(error);
        dispatch({ type: DELETE_DISPUTE_CODE_ERROR, error });
      }
    );
  };
};

export const deleteDisputeDocAction = (credentials) => {
  return (dispatch) => {
    deleteDisputeDocument(credentials).then(
      (res) => {
        if (res.hasOwnProperty("success") && res.success === true) {
          toast.success(res.response.message);
        } else if (res.hasOwnProperty("success") && res.success === false) {
          toast.error(res.response.message);
        }
      },
      (error) => {
        console.log(error);
        toast.error("Failed to delete document");
      }
    );
  };
};