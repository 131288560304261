import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { HttpInterceptor } from "../config/HttpInterceptor";
import RootReducer from "./reducers/RootReducer";

export const middlewares = [thunk];
export const createStoreWithMiddleware = applyMiddleware(...middlewares)(
  createStore
);
export const store = createStoreWithMiddleware(RootReducer);
HttpInterceptor(store);
