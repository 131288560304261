import {
  LOAD_DISPUTES,
  LOAD_DISPUTES_ERROR,
  CREATE_DISPUTE_LOADING,
  CLEAR_CREATE_DISPUTE,
  CREATE_DISPUTE_CODE_ERROR,
  CREATE_DISPUTE_SUCCESS,
  CREATE_DISPUTE_FAILED,
} from "../../actiontypes/user/Dispute";

const initState = {
  createDispute: "",
  loadDisputes: { response: { data: [] } },
};

const DisputeReducer = (state = initState, action) => {
  switch (action.type) {
    case CLEAR_CREATE_DISPUTE:
      return {
        ...state,
        createDispute: "",
      };
    case CREATE_DISPUTE_LOADING:
      return {
        ...state,
        createDispute: "loading",
      };
    case CREATE_DISPUTE_SUCCESS:
      return {
        ...state,
        createDispute: action.res,
      };
    case CREATE_DISPUTE_FAILED:
      return {
        ...state,
        createDispute: action.res,
      };
    case CREATE_DISPUTE_CODE_ERROR:
      return {
        ...state,
        loadDisputes: "Error failed to create dispute",
      };
    case LOAD_DISPUTES:
      return {
        ...state,
        loadDisputes: action.res,
      };
    case LOAD_DISPUTES_ERROR:
      return {
        ...state,
        loadDisputes: action.error,
      };
    default:
      return state;
  }
};
export default DisputeReducer;
