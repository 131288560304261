import React from 'react'

export default function Dashboard () {
  return (
    <>
      <div className='intro_text'>
        <p>Welcome to ANZ Transfer Of Value Application !</p>
      </div>
      <div >
        <p className='intro_sub_text'>Please click on Admin menu above to see list of functions.</p>
      </div>
    </>
  )
}
