import  { AuthProvider }  from '@elilillyco/spa_auth';
//import authConfig from '../config';

const config = {
   clientId: "7d2968cd-8c3b-4cdb-b0f0-0d992794d53b",
   openIdCallback: "/auth/callback",  //optional - default value = '/auth/callback'
   authority: "https://login.microsoftonline.com/18a59a81-eea8-4c30-948a-d8824cdc2580",   //optional - default value = 'https://login.microsoftonline.com/18a59a81-eea8-4c30-948a-d8824cdc2580 -  This is Lilly production Azure AD.  Both production and non-production apps are registered here. 
   scopes: ['openid', 'profile', 'user.read', 'email'],  //optional - default value = ['openid', 'profile', 'user.read', 'email']
   isGatewayScopeRequired: true,  //optional - default value = false, set it to true if your app needs an access token to access Gateway
   gatewayScopeType: 'production',  //optional - default value = 'non_production', permissible values = 'production', 'non_production' 
   // set it to 'production' when you want to access production Gateway, and set it to 'non_production' when you want to access QA Gateway
   logLevel: 'DEBUG', //optional - default value = 'INFO', permissible values = 'TRACE', 'DEBUG', 'INFO', 'WARN', 'ERROR' and 'FATAL' 
   cacheLocation: 'sessionStorage' //optional - default value = 'sessionStorage', permissible values = 'sessionStorage', 'localStorage'
}
export const authProvider = new AuthProvider(config);

export const lillyConfig = {
  authUrl: "https://account.lilly.com/en-AU/signin?title=ANZ%20TOV%20Application&url=https://d2i0egsz29jx7j.cloudfront.net/user/login"
}

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
}

export const callGraph = async () => {
  const { accessToken } = await authProvider.getAccessToken()
  const headers = new Headers()
  const bearer = 'Bearer ' + accessToken
  headers.append('Authorization', bearer)
  const options = {
    method: 'GET',
    headers: headers
  }
  const graphEndpoint = 'https://graph.microsoft.com/v1.0/me'

  const response = await fetch(graphEndpoint, options)
  const data = await response.json()
  return data
}
