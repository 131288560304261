import HttpService from "./HttpService";

export const registerAdmin = (credentials) => {
  const http = new HttpService();
  let url = "api/admin/register";
  const tokenId = "admin-token";
  return http
    .postData(credentials, url, "POST", tokenId)
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const updateAdmin = (credentials) => {
  const http = new HttpService();
  let url = "api/admin/edit";
  const tokenId = "admin-token";
  return http
    .postData(credentials, url, "POST", tokenId)
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const deleteAdmin = (credentials) => {
  const http = new HttpService();
  let url = "api/admin/delete";
  const tokenId = "admin-token";
  return http
    .postData(credentials, url, "POST", tokenId)
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const viewAdmins = () => {
  const http = new HttpService();
  let url = "api/admin/view-list";
  const tokenId = "admin-token";
  const credentials = {};
  return http
    .postData(credentials, url, "POST", tokenId)
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const logAdminIn = (credentials) => {
  const http = new HttpService();
  let url = "api/admin/login";
  return http
    .postData(credentials, url, "POST")
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};
