import { loadAllTovs } from "../../../services/user/TovService";
import { LOAD_TOVS, LOAD_TOVS_ERROR } from "../../actiontypes/user/Tov";

export const loadTovReports = (credentials) => {
  return (dispatch) => {
    loadAllTovs(credentials).then(
      (res) => {
        console.log(res);
        dispatch({ type: LOAD_TOVS, res });
      },
      (error) => {
        console.log(error);
        dispatch({ type: LOAD_TOVS_ERROR, error });
      }
    );
  };
};
