import React from 'react'
import { ToastContainer } from 'react-toastify'

const NetworkDetector = () => {
  return (
    <ToastContainer
      position='top-right'
      autoClose={4 * 1000}
      hideProgressBar
      newestOnTop
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  )
}

export default NetworkDetector
