import React, { useEffect } from 'react'
import { authProvider, callGraph } from '../../helpers/authProvider'
import { useDispatch } from 'react-redux'
import { adminLoginAction } from '../../stores/actions/admin/AuthActions'

const AdminLogin = props => {
  const dispatch = useDispatch()

  const fetchAuthenticated = async () => {
    const isUserLoggedIn = await authProvider.isLoggedIn()
    if (isUserLoggedIn === false) {
      authProvider.login()
    } else {
      const gatewayAccessToken = await authProvider.getGatewayAccessToken()
      localStorage.setItem("gateway-access-token", gatewayAccessToken.accessToken);
      const response = await callGraph()
      dispatch(adminLoginAction(response, props))
    }
  }

  useEffect(() => {
    fetchAuthenticated()
  })

  return <></>
}
export default AdminLogin
