import React, { useEffect } from 'react'
import { authProvider, callGraph } from '../../helpers/authProvider'
import { useDispatch } from 'react-redux'
import { adminLoginAction } from '../../stores/actions/admin/AuthActions'

const AdminAuthCallback = props => {
  const dispatch = useDispatch()

  const processLogin = async () => {
    const gatewayAccessToken = await authProvider.getGatewayAccessToken()
    localStorage.setItem("gateway-access-token", gatewayAccessToken.accessToken);
    const response = await callGraph()
    dispatch(adminLoginAction(response, props))
  }

  useEffect(() => {
    authProvider.handlePromiseRedirectCallback(processLogin)
  })
  return <div></div>
}
export default AdminAuthCallback
