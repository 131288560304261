import React, { useEffect } from 'react'
import { lillyConfig } from '../../helpers/authProvider'
import { useDispatch } from 'react-redux'
import jwt_decode from 'jwt-decode'
import { userLoginAction } from '../../stores/actions/user/AuthActions'

const UserLogin = props => {
  const dispatch = useDispatch()
  const paramsString = props.location.search
  const params = new URLSearchParams(paramsString)
  const token = params.get('access_token')

  const goToPassportLogin = () => {
    window.location.href = lillyConfig.authUrl
  }

  const requestUserProfile = () => {
    const userDecoded = jwt_decode(token)
    console.log("userDecoded-->", userDecoded);
    localStorage.setItem("passport-access-token", token);
    dispatch(userLoginAction(userDecoded, props))
  }

  useEffect(() => {
    if (token !== null) {
      requestUserProfile()
    } else {
      goToPassportLogin()
    }
  })
  return (
    <div>
      <p></p>
    </div>
  )
}
export default UserLogin
