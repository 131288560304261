import React from 'react'
import NetworkDetector from './NetworkDetector'

const Template = props => {
  const { children } = props
  return (
    <>
      <div className='container intro'>
        <NetworkDetector />
        {children}
      </div>
    </>
  )
}

export default Template
