import React from 'react'
import { Link } from 'react-router-dom'

export default function Dashboard () {
  return (
    <>
      <div className='intro_text'>
        <p>Welcome to ANZ Transfer Of Value Application !</p>
      </div>
      <div>
        <Link to="/user/tov-report">
          <p className='intro_sub_text'>
            Please click on Transfer Of Value Report menu above to view your
            Transfer Of Value data.
          </p>
        </Link>
      </div>
    </>
  )
}
