export const LOAD_DISPUTES = 'LOAD_DISPUTES';

export const LOAD_DISPUTES_ERROR = 'LOAD_DISPUTES_ERROR';
export const LOAD_RESOLVED_DISPUTES = 'LOAD_RESOLVED_DISPUTES';

export const LOAD_RESOLVED_DISPUTES_ERROR = 'LOAD_RESOLVED_DISPUTES_ERROR';

export const CREATE_DISPUTE_LOADING = 'CREATE_DISPUTE_LOADING';

export const CLEAR_CREATE_DISPUTE = 'CLEAR_CREATE_DISPUTE';

export const CREATE_DISPUTE_CODE_ERROR = 'CREATE_DISPUTE_CODE_ERROR';

export const CREATE_DISPUTE_SUCCESS = 'CREATE_DISPUTE_SUCCESS';

export const CREATE_DISPUTE_FAILED = 'CREATE_DISPUTE_FAILED';


export const DELETE_DISPUTE_LOADING = 'DELETE_DISPUTE_LOADING';

export const CLEAR_DELETE_DISPUTE = 'CLEAR_DELETE_DISPUTE';

export const DELETE_DISPUTE_CODE_ERROR = 'DELETE_DISPUTE_CODE_ERROR';

export const DELETE_DISPUTE_SUCCESS = 'DELETE_DISPUTE_SUCCESS';

export const DELETE_DISPUTE_FAILED = 'DELETE_DISPUTE_FAILED';


export const EDIT_DISPUTE_LOADING = 'EDIT_DISPUTE_LOADING';

export const CLEAR_EDIT_DISPUTE = 'CLEAR_EDIT_DISPUTE';

export const EDIT_DISPUTE_CODE_ERROR = 'EDIT_DISPUTE_CODE_ERROR';

export const EDIT_DISPUTE_SUCCESS = 'EDIT_DISPUTE_SUCCESS';

export const EDIT_DISPUTE_FAILED = 'EDIT_DISPUTE_FAILED';