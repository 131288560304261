export const LOAD_DISPUTES = 'LOAD_DISPUTES';

export const LOAD_DISPUTES_ERROR = 'LOAD_DISPUTES_ERROR';


export const CREATE_DISPUTE_LOADING = 'CREATE_DISPUTE_LOADING';

export const CLEAR_CREATE_DISPUTE = 'CLEAR_CREATE_DISPUTE';

export const CREATE_DISPUTE_CODE_ERROR = 'CREATE_DISPUTE_CODE_ERROR';

export const CREATE_DISPUTE_SUCCESS = 'CREATE_DISPUTE_SUCCESS';

export const CREATE_DISPUTE_FAILED = 'CREATE_DISPUTE_FAILED';