import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// import Home from "../components/auth/Home";
import Unauthorized from "../components/auth/Unauthorized";
import { Guard } from "./Guard";
import AdminPrivateRoutes from "./AdminPrivateRoutes";
import UserPrivateRoutes from "./UserPrivateRoutes";
import UserLogin from "../components/auth/UserLogin";
import AdminLogin from "../components/auth/AdminLogin";
import AdminAuthCallback from "../components/auth/AdminAuthCallback";

const Routes = (props) => {
  return (
    <div>
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => <Redirect to={{ pathname: "/user/login" }} />}
        />
        {/* <Route path="/home" component={Home} /> */}
        <Route path="/admin/unauthorized" component={Unauthorized} />
        <Route path="/admin/login" component={AdminLogin} />
        <Route path="/auth/callback" component={AdminAuthCallback} />
        <Route path="/user/login" component={UserLogin} />
        <Guard
          path="/admin"
          token="admin-token"
          routeRedirect="/admin/login"
          component={AdminPrivateRoutes}
        />
        <Guard
          path="/user"
          token="user-token"
          routeRedirect="/user/login"
          component={UserPrivateRoutes}
        />
      </Switch>
    </div>
  );
};

export default Routes;
