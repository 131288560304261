import { toast } from 'react-toastify'
import {
  raiseDispute,
  loadAllDisputes
} from "../../../services/user/DisputeService";
import {
  LOAD_DISPUTES,
  LOAD_DISPUTES_ERROR,
  CREATE_DISPUTE_LOADING,
  CLEAR_CREATE_DISPUTE,
  CREATE_DISPUTE_CODE_ERROR,
  CREATE_DISPUTE_SUCCESS,
  CREATE_DISPUTE_FAILED,
} from "../../actiontypes/user/Dispute";

export const raiseDisputeAction = (credentials, props) => {
  return (dispatch) => {
    let creds = {};
    creds.tov_id = credentials.id;
    dispatch({type: CLEAR_CREATE_DISPUTE});
    dispatch({type: CREATE_DISPUTE_LOADING});
    raiseDispute(creds).then((res)=>{
      if(res.hasOwnProperty("success") && res.success === true){
        dispatch({type: CREATE_DISPUTE_SUCCESS, res});
        props.history.push('/user/dispute-success');
        toast.success(res.response.message)
      } else if(res.hasOwnProperty("success") && res.success === false){
        dispatch({type: CREATE_DISPUTE_FAILED, res});
        toast.error(res.response.message)
      }
    },
    error=>{
      console.log(error);
        dispatch({ type: CREATE_DISPUTE_CODE_ERROR, error });
    }
    );
  };
};

export const loadDisputeReports = () => {
  return (dispatch) => {
    loadAllDisputes().then(
      (res) => {
        console.log(res);
        dispatch({ type: LOAD_DISPUTES, res });
      },
      (error) => {
        console.log(error);
        dispatch({ type: LOAD_DISPUTES_ERROR, error });
      }
    );
  };
};

