import HttpService from "./HttpService";
import download from 'downloadjs';

export const raiseDispute = (credentials) => {
  const http = new HttpService();
  let url = "api/admin/create-dispute";
  const tokenId = "admin-token";
  return http
    .postData(credentials, url, "POST",tokenId)
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export const loadAllDisputes = () => {
  const http = new HttpService();
  let url = "api/admin/view-dispute";
  const tokenId = "admin-token";
  const credentials = {};
  return http
    .postData(credentials, url, "POST", tokenId)
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const loadResolvedDisputes = () => {
  const http = new HttpService();
  let url = "api/admin/view-resolved-dispute";
  const tokenId = "admin-token";
  const credentials = {};
  return http
    .postData(credentials, url, "POST", tokenId)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const updateDispute = (credentials) => {
  const http = new HttpService();
  let url = "api/admin/edit-dispute";
  const tokenId = "admin-token";
  return http
    .postData(credentials, url, "POST", tokenId)
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const deleteDispute = (credentials) => {
  const http = new HttpService();
  let url = "api/admin/delete-dispute";
  const tokenId = "admin-token";
  return http
    .postData(credentials, url, "POST", tokenId)
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const downloadDisputeDocument = (credentials) => {
  const http = new HttpService();
  let url = "api/admin/get-dispute-document";
  const tokenId = "admin-token";
  return http
    .postDataForFileDownload(credentials, url, "POST", tokenId)
    .then((res) => {
      console.log("encoded>>>>", res);
      download(atob(res.response), credentials.file_name, {type: credentials.file_type})
      //return res;
    })
    .catch((error) => {
      console.log(error);
      //return error;
    });
};

export const downloadTemplate = () => {
  const http = new HttpService();
  let url = "api/admin/get-template";
  const tokenId = "admin-token";

  return http
    .postDataForFileDownload( {}, url, "POST", tokenId)
    .then((res) => {
      download(atob(res.response), 'TOV Template.xlsx', {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
    })
    .catch((error) => {
      console.log(error);
    });
};

export const deleteDisputeDocument = (credentials) => {
  const http = new HttpService();
  let url = "api/admin/delete-dispute-document";
  const tokenId = "admin-token";
  return http
    .postData(credentials, url, "POST", tokenId)
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};