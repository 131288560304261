import fetchIntercept from 'fetch-intercept';

export const HttpInterceptor = (store) => fetchIntercept.register({
    request: function(url, config){
        //console.log("interceptor-->", url);
        return [url, config];
    },

    requestError: function(error){
        //console.log("interceptor-->", error);
        return Promise.reject(error);
    },

    response: function(response){
        //console.log("interceptor-->", response.status)
        if(response.status === 401){
            console.log('this is a 401 error');
        }
        return response
    },

    responseError: function(response){
        //console.log("interceptor-->", response);
        return response;
    },
})