import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import { authProvider } from '../../../helpers/authProvider'

const bottomStyle = { marginBottom: '0rem' }

const Nav = props => {
  let history = useHistory()
  const token = localStorage.getItem('admin-token')
  const auth = jwt_decode(token)

  const logoutAction = e => {
    e.preventDefault()
    localStorage.removeItem('gateway-access-token')
    localStorage.removeItem('admin-token')
    authProvider.signout();
    history.push('/admin/login')
  }

  return (
    <div className='container menubar'>
      <div className='row'>
        <div className='col-lg-3 col-md-3 col-sm-3 brandname'>
          <Link
            to={`${props.props.match.path}/dashboard`}
            style={{ color: '#000000', fontWeight: 'bold' }}
          >
            <span>ANZ Transfer Of Value</span>
          </Link>
        </div>
        <div className='col-md-6 col-lg-6 col-sm-6'>
          <nav className='navbar navbar-expand-lg'>
            <ul className='navbar-nav nav_bar'>
              {/* <li className='nav-item active'>
                <Link
                  className='nav-link'
                  to={`${props.props.match.path}/dashboard`}
                >
                  Home
                </Link>
              </li> */}
              <li className='nav-item dropdown'>
                <a
                  className='nav-link dropdown-toggle'
                  role='button'
                  data-toggle='dropdown'
                  href='/#'
                >
                  Admin
                </a>
                <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                  <Link
                    className='dropdown-item'
                    to={`${props.props.match.path}/tov-upload`}
                  >
                    Upload TOV data
                  </Link>
                  <Link
                    className='dropdown-item'
                    to={`${props.props.match.path}/tov-data`}
                  >
                    Show TOV data
                  </Link>
                  <Link
                    className='dropdown-item'
                    to={`${props.props.match.path}/manage-dispute`}
                  >
                    Manage Dispute
                  </Link>
                  
                  <Link
                    className='dropdown-item'
                    to={`${props.props.match.path}/user-management`}
                  >
                    User Management
                  </Link>
                </div>
              </li>
            </ul>
          </nav>
        </div>
        <div className='col-lg-3 col-md-3 col-sm-3'>
          <nav className='navbar navbar-expand-lg'>
            <ul className='navbar-nav nav_bar'>
              <li className='nav-item dropdown'>
                <a
                  className='nav-link'
                  href='/#'
                  id='navbarDropdown'
                  role='button'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  <div className='d-flex'>
                    <div className='user_img'>
                      <img
                        src='/img/user.png'
                        alt='user'
                        height='20'
                        width='20'
                      />
                    </div>
                    <div className='user_details'>
                      <p className='text-trunc'>
                        <b>{auth.user.name}</b>
                      </p>
                      <p style={bottomStyle}>Role: Admin</p>
                    </div>
                  </div>
                </a>
                <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                  <a className='dropdown-item' href='/#' onClick={logoutAction}>
                    Logout
                  </a>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Nav
