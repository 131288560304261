import { toast } from "react-toastify";
import { registerAdmin, updateAdmin, deleteAdmin, logAdminIn, viewAdmins } from "../../../services/admin/AuthService";
import {
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  CLEAR_AUTH_STATE,
  CREATE_ADMIN_LOADING,
  CLEAR_CREATE_ADMIN,
  CREATE_ADMIN_CODE_ERROR,
  CREATE_ADMIN_SUCCESS,
  CREATE_ADMIN_FAILED,
  EDIT_ADMIN_LOADING,
  CLEAR_EDIT_ADMIN,
  EDIT_ADMIN_CODE_ERROR,
  EDIT_ADMIN_SUCCESS,
  EDIT_ADMIN_FAILED,
  DELETE_ADMIN_LOADING,
  CLEAR_DELETE_ADMIN,
  DELETE_ADMIN_CODE_ERROR,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAILED,
  LOAD_ADMINS,
  LOAD_ADMINS_ERROR,
} from "../../actiontypes/admin/Auth";

export const registerAdminAction = (credentials) => {
  return (dispatch) => {
    dispatch({ type: CLEAR_CREATE_ADMIN });
    dispatch({ type: CREATE_ADMIN_LOADING });
    registerAdmin(credentials).then(
      (res) => {
        if (res.hasOwnProperty("success") && res.success === true) {
          dispatch({ type: CREATE_ADMIN_SUCCESS, res });
          toast.success(res.response.message);
        } else if (res.hasOwnProperty("success") && res.success === false) {
          dispatch({ type: CREATE_ADMIN_FAILED, res });
          toast.error(res.response.message);
        }
      },
      (error) => {
        console.log(error);
        dispatch({ type: CREATE_ADMIN_CODE_ERROR, error });
      }
    );
  };
};

export const updateAdminAction = (credentials) => {
  return (dispatch) => {
    dispatch({ type: CLEAR_EDIT_ADMIN });
    dispatch({ type: EDIT_ADMIN_LOADING });
    updateAdmin(credentials).then(
      (res) => {
        if (res.hasOwnProperty("success") && res.success === true) {
          dispatch({ type: EDIT_ADMIN_SUCCESS, res });
          toast.success(res.response.message);
        } else if (res.hasOwnProperty("success") && res.success === false) {
          dispatch({ type: EDIT_ADMIN_FAILED, res });
          toast.error(res.response.message);
        }
      },
      (error) => {
        console.log(error);
        dispatch({ type: EDIT_ADMIN_CODE_ERROR, error });
      }
    );
  };
};

export const deleteAdminAction = (credentials) => {
  return (dispatch) => {
    dispatch({ type: CLEAR_DELETE_ADMIN });
    dispatch({ type: DELETE_ADMIN_LOADING });
    deleteAdmin(credentials).then(
      (res) => {
        if (res.hasOwnProperty("success") && res.success === true) {
          dispatch({ type: DELETE_ADMIN_SUCCESS, res });
          toast.success(res.response.message);
        } else if (res.hasOwnProperty("success") && res.success === false) {
          dispatch({ type: DELETE_ADMIN_FAILED, res });
          toast.error(res.response.message);
        }
      },
      (error) => {
        console.log(error);
        dispatch({ type: DELETE_ADMIN_CODE_ERROR, error });
      }
    );
  };
};



export const loadAdminsList = () => {
  return (dispatch) => {
    viewAdmins().then(
      (res) => {
        console.log(res);
        dispatch({ type: LOAD_ADMINS, res });
      },
      (error) => {
        console.log(error);
        dispatch({ type: LOAD_ADMINS_ERROR, error });
      }
    );
  };
};

export const adminLoginAction = (data, props) => {
  return (dispatch) => {
    let credentials = {};
    let adminName = data.givenName + " " + data.surname;
    let adminEmail = data.mail;
    credentials.name = adminName;
    credentials.email = adminEmail;
    dispatch({ type: LOGIN_LOADING });
    logAdminIn(credentials).then(
      (res) => {
        if (res.hasOwnProperty("success") && res.success === true) {
          dispatch({ type: LOGIN_SUCCESS });
          localStorage.setItem("admin-token", res.response.token);
          setTimeout(() => {
            props.history.push("/admin/dashboard");
            dispatch({ type: CLEAR_AUTH_STATE });
          }, 1500);
        } else if (res.hasOwnProperty("success") && res.success === false) {
          dispatch({ type: LOGIN_ERROR, res });
          localStorage.removeItem("admin-token");
          props.history.push("/admin/unauthorized");
        }
      },
      (errors) => {
        console.log(errors);
      }
    );
  };
};

export const clearAdminLogInState = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_AUTH_STATE });
  };
};
