import {
  CREATE_ADMIN_LOADING,
  CLEAR_CREATE_ADMIN,
  CREATE_ADMIN_CODE_ERROR,
  CREATE_ADMIN_SUCCESS,
  CREATE_ADMIN_FAILED,
  EDIT_ADMIN_LOADING,
  CLEAR_EDIT_ADMIN,
  EDIT_ADMIN_CODE_ERROR,
  EDIT_ADMIN_SUCCESS,
  EDIT_ADMIN_FAILED,
  DELETE_ADMIN_LOADING,
  CLEAR_DELETE_ADMIN,
  DELETE_ADMIN_CODE_ERROR,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAILED,
  LOAD_ADMINS,
  LOAD_ADMINS_ERROR,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  CLEAR_AUTH_STATE,
} from "../../actiontypes/admin/Auth";

const initState = {
  registerAdmin: "",
  updateAdmin: "",
  deleteAdmin: "",
  loginState: "",
  loadAdmins: { response: { data: [] } },
};

const AuthReducer = (state = initState, action) => {
  switch (action.type) {
    case CLEAR_CREATE_ADMIN:
      return {
        ...state,
        registerAdmin: "",
      };
    case CREATE_ADMIN_LOADING:
      return {
        ...state,
        registerAdmin: "loading",
      };
    case CREATE_ADMIN_SUCCESS:
      return {
        ...state,
        registerAdmin: action.res,
      };
    case CREATE_ADMIN_FAILED:
      return {
        ...state,
        registerAdmin: action.res,
      };
    case CREATE_ADMIN_CODE_ERROR:
      return {
        ...state,
        registerAdmin: "Error failed to create admin",
      };
    case CLEAR_EDIT_ADMIN:
      return {
        ...state,
        updateAdmin: "",
      };
    case EDIT_ADMIN_LOADING:
      return {
        ...state,
        updateAdmin: "loading",
      };
    case EDIT_ADMIN_SUCCESS:
      return {
        ...state,
        updateAdmin: action.res,
      };
    case EDIT_ADMIN_FAILED:
      return {
        ...state,
        updateAdmin: action.res,
      };
    case EDIT_ADMIN_CODE_ERROR:
      return {
        ...state,
        updateAdmin: "Error failed to edit admin",
      };
    case CLEAR_DELETE_ADMIN:
      return {
        ...state,
        deleteAdmin: "",
      };
    case DELETE_ADMIN_LOADING:
      return {
        ...state,
        deleteAdmin: "loading",
      };
    case DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        deleteAdmin: action.res,
      };
    case DELETE_ADMIN_FAILED:
      return {
        ...state,
        deleteAdmin: action.res,
      };
    case DELETE_ADMIN_CODE_ERROR:
      return {
        ...state,
        deleteAdmin: "Error failed to delete admin",
      };
    case LOAD_ADMINS:
      return {
        ...state,
        loadAdmins: action.res,
      };
    case LOAD_ADMINS_ERROR:
      return {
        ...state,
        loadAdmins: action.error,
      };
    case CLEAR_AUTH_STATE:
      return {
        ...state,
        loginState: "",
      };
    case LOGIN_LOADING:
      return {
        ...state,
        loginState: "loading",
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginState: action.res,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loginState: action.res,
      };
    default:
      return state;
  }
};

export default AuthReducer;
