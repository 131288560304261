import React from 'react';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='container footer_text'>
        Copyright @ 2022 Eli Lilly and Company. All rights reserved.
      </div>
    </footer>
  )
}

export default Footer
