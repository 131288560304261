import HttpService from "./HttpService";

export const uploadTovDocument = (credentials) => {
  const http = new HttpService();
  let url = "api/admin/upload-tov";
  const tokenId = "admin-token";
  return http
    .postData(credentials, url, "POST", tokenId)
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};


export const loadTovs = (credentials) => {
  const http = new HttpService();
  let url = "api/admin/view-tov";
  const tokenId = "admin-token";
  return http
    .postData(credentials, url, "POST", tokenId)
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const loadAllTovs = (credentials) => {
  const http = new HttpService();
  let url = "api/admin/view-all-tov";
  const tokenId = "admin-token";
  return http
    .postData(credentials, url, "POST", tokenId)
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const tovNotifyEmail = (credentials) => {
  const http = new HttpService();
  let url = "api/admin/tov-notify-email";
  const tokenId = "admin-token";
  return http
    .postData(credentials, url, "POST", tokenId)
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const updateTov = (credentials) => {
  const http = new HttpService();
  let url = "api/admin/edit-tov";
  const tokenId = "admin-token";
  return http
    .postData(credentials, url, "POST", tokenId)
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const deleteTov = (credentials) => {
  const http = new HttpService();
  let url = "api/admin/delete-tov";
  const tokenId = "admin-token";
  return http
    .postData(credentials, url, "POST", tokenId)
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};
