import { logUserIn } from '../../../services/user/AuthService'
import {
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  CLEAR_AUTH_STATE
} from '../../actiontypes/user/Auth'

export const userLoginAction = (data, props) => {
  return dispatch => {
    let credentials = {}
    let userName = data.firstName + ' ' + data.lastName
    let userEmail = data.email
    credentials.name = userName
    credentials.email = userEmail
    dispatch({ type: LOGIN_LOADING })
    logUserIn(credentials).then(
      res => {
        console.log(res)
        if (res.hasOwnProperty('success') && res.success === true) {
          dispatch({ type: LOGIN_SUCCESS })
          localStorage.setItem('user-token', res.response.token)
          setTimeout(() => {
            props.history.push('/user/tov-report')
            dispatch({ type: CLEAR_AUTH_STATE })
          }, 1500);
        } else if (res.hasOwnProperty('success') && res.success === false) {
          dispatch({ type: LOGIN_ERROR, res })
        }
      },
      errors => {
        console.log(errors)
      }
    )
  }
}

export const clearUserLogInState = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_AUTH_STATE })
    }
}
