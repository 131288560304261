import React from 'react'

const Topbar = () => {
  return (
    <header className='header-bg'>
      <div className=' container lilly_logo'>
        <img
          src='/img/lilly_logo.png'
          alt='lilly logo'
          height='48'
          width='90'
        />
      </div>
    </header>
  )
}

export default Topbar
