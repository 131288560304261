import {
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  CLEAR_AUTH_STATE
} from "../../actiontypes/user/Auth";

const initState = {
  loginState: ""
};

const AuthReducer = (state = initState, action) => {
  switch (action.type) {
    case CLEAR_AUTH_STATE:
      return {
        ...state,
        loginState: "",
      };
    case LOGIN_LOADING:
      return {
        ...state,
        loginState: "loading",
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginState: action.res,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loginState: action.res,
      };
    default:
      return state;
  }
};

export default AuthReducer;
