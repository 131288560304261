import React from 'react'

const RedirectPage = () => {
  return (
    <>
      <div className='intro_text'>
        <p></p>
      </div>
      <div>
        <p className='intro_sub_text'>
          <b>You wish to dispute an activity. A Lilly representative will contact
          you shortly.</b>
        </p>
      </div>
    </>
  )
}
export default RedirectPage
