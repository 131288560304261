import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
 
const bottomStyle = { marginBottom: '0rem' }
const _affiliateCode = "en-AU"
const _passportURL = "https://account.lilly.com/";
const _applicationURL  = `${window.location.origin.toLowerCase().replace('http:','https:')}/user/login`;
 
const Nav = props => {
  let history = useHistory()
  const token = localStorage.getItem('user-token')
  const auth = jwt_decode(token)
 
  const logoutAction = e => {
    e.preventDefault()
    localStorage.removeItem('user-token')
    localStorage.removeItem('passport-access-token');
    window.location.replace(manageLogout());
  }
 
  const manageLogout = () => {
    return  `${_passportURL}${_affiliateCode}/Logout/?url=${_applicationURL}`
  }

  return (
    <div className='container menubar'>
      <div className='row'>
        <div className='col-lg-3 col-md-3 col-sm-3 brandname'>
          <Link to={`${props.props.match.path}/tov-report`} style={{color: '#000000', fontWeight: 'bold'}}>
            <span>ANZ Transfer Of Value</span>
          </Link>
        </div>
        <div className='col-md-6 col-lg-6 col-sm-6'>
          <nav className='navbar navbar-expand-lg'>
            <ul className='navbar-nav nav_bar'>
              {/* <li className='nav-item active'>
                <Link
                  className='nav-link'
                  to={`${props.props.match.path}/dashboard`}
                >
                  Home
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className='nav-link nav-div'
                  to={`${props.props.match.path}/tov-report`}
                >
                  Transfer Of Value Report
                </Link>
              </li> */}
            </ul>
          </nav>
        </div>
        <div className='col-lg-3 col-md-3 col-sm-3'>
          <nav className='navbar navbar-expand-lg'>
            <ul className='navbar-nav nav_bar'>
              <li className='nav-item dropdown'>
                <a
                  className='nav-link'
                  href='/#'
                  id='navbarDropdown'
                  role='button'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  <div className='d-flex'>
                    <div className='user_img'>
                      <img
                        src='/img/user.png'
                        alt='user'
                        height='20'
                        width='20'
                      />
                    </div>
                    <div className='user_details'>
                      <p className='text-trunc'>
                        <b>{auth.user.name}</b>
                      </p>
                      <p style={bottomStyle}>Role: HCP</p>
                    </div>
                  </div>
                </a>
                <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                  <a className='dropdown-item' href='/#' onClick={logoutAction}>
                    Logout
                  </a>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Nav
