class HttpService {
  //baseUrl = "http://localhost:5000";
  baseUrl = "https://ll528g0em8.execute-api.us-east-2.amazonaws.com/Prod";

  postData = async (item, addedUrl, postType, tokenId = "") => {
    const token = await localStorage.getItem(tokenId);
    const passportToken = await localStorage.getItem('passport-access-token');
    const requestOptions = this.postRequestOptions(passportToken, token, item, postType);
    return fetch(this.baseUrl + "/" + addedUrl, requestOptions).then(
      (response) => response.json()
    );
  };
  postRequestOptions = (passportToken, token, item, postType) => {
    let requestOptions = {
      method: postType,
      cache: "no-cache",
      headers: {
        Authorization: passportToken,
        jwt_token: token,
        "Content-type": "Application/json",
      },
      body: JSON.stringify(item),
    };
    return requestOptions;
  };

  postDataForFileDownload = async (item, addedUrl, postType, tokenId = "") => {
    const token = await localStorage.getItem(tokenId);
    const passportToken = await localStorage.getItem('passport-access-token');
    const requestOptions = this.postFileDownloadRequestOptions(passportToken, token, item, postType);
    return fetch(this.baseUrl + "/" + addedUrl, requestOptions).then(
      (response) => response.blob()
    );
  };
  postFileDownloadRequestOptions = (passportToken, token, item, postType) => {
    let requestOptions = {
      method: postType,
      cache: "no-cache",
      headers: {
        Authorization: passportToken,
        jwt_token: token,
        "Content-type": "Application/json",
      },
      responseType: 'blob',
      body: JSON.stringify(item),
    };
    return requestOptions;
  };

  getData = async (addedUrl, tokenId = "") => {
    const token = await localStorage.getItem(tokenId);
    const passportToken = await localStorage.getItem('passport-access-token');
    const requestOptions = this.getRequestOptions(passportToken, token);
    return fetch(this.baseUrl + "/" + addedUrl, requestOptions).then(
      (response) => response.json()
    );
  };
  getRequestOptions = (passportToken, token) => {
    let requestOptions = {
      method: "GET",
      cache: "no-cache",
      headers: {
        Authorization: passportToken,
        jwt_token: token,
        "Content-type": "Application/json",
      },
    };
    return requestOptions;
  };

  deleteData = async (addedUrl, tokenId = "") => {
    const token = await localStorage.getItem(tokenId);
    const passportToken = await localStorage.getItem('passport-access-token');
    const requestOptions = this.deleteRequestOptions(passportToken, token);
    return fetch(this.baseUrl + "/" + addedUrl, requestOptions).then(
      (response) => response.json()
    );
  };
  deleteRequestOptions = (passportToken, token) => {
    let requestOptions = {
      method: "DELETE",
      cache: "no-cache",
      headers: {
        Authorization: passportToken,
        jwt_token: token,
        "Content-type": "Application/json",
      },
    };
    return requestOptions;
  };

  postDataWithFormData = async (item, addedUrl, postType, tokenId = "") => {
    const token = await localStorage.getItem(tokenId);
    const passportToken = await localStorage.getItem('passport-access-token');
    const requestOptions = this.postRequestOptionsWithFormData(passportToken, token, item, postType);
    return fetch(this.baseUrl + "/" + addedUrl, requestOptions).then(
      (response) => response.json()
    );
  };
  postRequestOptionsWithFormData = (passportToken, token, item, postType) => {
    let requestOptions = {
      method: postType,
      cache: "no-cache",
      headers: {
        Authorization: passportToken,
        jwt_token: token,
      },
      body: item,
    };
    return requestOptions;
  };
}

export default HttpService;
