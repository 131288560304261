import HttpService from "./HttpService";
import fileDownload from 'js-file-download';

export const raiseDispute = (credentials) => {
  const http = new HttpService();
  let url = "api/user/create-dispute";
  const tokenId = "user-token";
  return http
    .postData(credentials, url, "POST",tokenId)
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export const loadAllDisputes = () => {
  const http = new HttpService();
  let url = "api/user/view-dispute";
  const tokenId = "user-token";
  const credentials = {};
  return http
    .postData(credentials, url, "POST", tokenId)
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const downloadDisputeDocument = (credentials) => {
  //console.log("credentials-->", credentials);
  const http = new HttpService();
  let url = "api/user/get-dispute-document";
  const tokenId = "user-token";
  return http
    .postDataForFileDownload(credentials, url, "POST", tokenId)
    .then((res) => {
      fileDownload(res, credentials.file_name)
      //return res;
    })
    .catch((error) => {
      console.log(error);
      //return error;
    });
};
