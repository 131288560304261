export const CREATE_ADMIN_LOADING = 'CREATE_ADMIN_LOADING';

export const CLEAR_CREATE_ADMIN = 'CLEAR_CREATE_ADMIN';

export const CREATE_ADMIN_CODE_ERROR = 'CREATE_ADMIN_CODE_ERROR';

export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS';

export const CREATE_ADMIN_FAILED = 'CREATE_ADMIN_FAILED';


export const EDIT_ADMIN_LOADING = 'EDIT_ADMIN_LOADING';

export const CLEAR_EDIT_ADMIN = 'CLEAR_EDIT_ADMIN';

export const EDIT_ADMIN_CODE_ERROR = 'EDIT_ADMIN_CODE_ERROR';

export const EDIT_ADMIN_SUCCESS = 'EDIT_ADMIN_SUCCESS';

export const EDIT_ADMIN_FAILED = 'EDIT_ADMIN_FAILED';


export const DELETE_ADMIN_LOADING = 'DELETE_ADMIN_LOADING';

export const CLEAR_DELETE_ADMIN = 'CLEAR_DELETE_ADMIN';

export const DELETE_ADMIN_CODE_ERROR = 'DELETE_ADMIN_CODE_ERROR';

export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';

export const DELETE_ADMIN_FAILED = 'DELETE_ADMIN_FAILED';


export const LOAD_ADMINS = 'LOAD_ADMINS';

export const LOAD_ADMINS_ERROR = 'LOAD_ADMINS_ERROR';


export const LOGIN_LOADING = 'LOGIN_AUTH';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const LOGIN_ERROR = 'LOGIN_ERROR';

export const CLEAR_AUTH_STATE = 'CLEAR_AUTH_STATE';