import {
  LOAD_DISPUTES,
  LOAD_DISPUTES_ERROR,
  LOAD_RESOLVED_DISPUTES,
  LOAD_RESOLVED_DISPUTES_ERROR,
  CREATE_DISPUTE_LOADING,
  CLEAR_CREATE_DISPUTE,
  CREATE_DISPUTE_CODE_ERROR,
  CREATE_DISPUTE_SUCCESS,
  CREATE_DISPUTE_FAILED,
  DELETE_DISPUTE_LOADING,
  CLEAR_DELETE_DISPUTE,
  DELETE_DISPUTE_CODE_ERROR,
  DELETE_DISPUTE_SUCCESS,
  DELETE_DISPUTE_FAILED,
  EDIT_DISPUTE_LOADING,
  CLEAR_EDIT_DISPUTE,
  EDIT_DISPUTE_CODE_ERROR,
  EDIT_DISPUTE_SUCCESS,
  EDIT_DISPUTE_FAILED,
} from "../../actiontypes/admin/Dispute";

const initState = {
  createDispute: "",
  updateDispute: "",
  deleteDispute: "",
  loadDisputes: { response: { data: [] } },
  loadResDisputes: { response: { data: [] } },
};

const DisputeReducer = (state = initState, action) => {
  switch (action.type) {
    case CLEAR_CREATE_DISPUTE:
      return {
        ...state,
        createDispute: "",
      };
    case CREATE_DISPUTE_LOADING:
      return {
        ...state,
        createDispute: "loading",
      };
    case CREATE_DISPUTE_SUCCESS:
      return {
        ...state,
        createDispute: action.res,
      };
    case CREATE_DISPUTE_FAILED:
      return {
        ...state,
        createDispute: action.res,
      };
    case CREATE_DISPUTE_CODE_ERROR:
      return {
        ...state,
        loadDisputes: "Error failed to create dispute",
      };
    case CLEAR_DELETE_DISPUTE:
      return {
        ...state,
        deleteDispute: "",
      };
    case DELETE_DISPUTE_LOADING:
      return {
        ...state,
        deleteDispute: "loading",
      };
    case DELETE_DISPUTE_SUCCESS:
      return {
        ...state,
        deleteDispute: action.res,
      };
    case DELETE_DISPUTE_FAILED:
      return {
        ...state,
        deleteDispute: action.res,
      };
    case DELETE_DISPUTE_CODE_ERROR:
      return {
        ...state,
        deleteDispute: "Error failed to delete dispute",
      };
    case CLEAR_EDIT_DISPUTE:
      return {
        ...state,
        updateDispute: "",
      };
    case EDIT_DISPUTE_LOADING:
      return {
        ...state,
        updateDispute: "loading",
      };
    case EDIT_DISPUTE_SUCCESS:
      return {
        ...state,
        updateDispute: action.res,
      };
    case EDIT_DISPUTE_FAILED:
      return {
        ...state,
        updateDispute: action.res,
      };
    case EDIT_DISPUTE_CODE_ERROR:
      return {
        ...state,
        updateDispute: "Error failed to update dispute",
      };
    case LOAD_DISPUTES:
      return {
        ...state,
        loadDisputes: action.res,
      };
    case LOAD_DISPUTES_ERROR:
      return {
        ...state,
        loadDisputes: action.error,
      };
    case LOAD_RESOLVED_DISPUTES:
      return {
        ...state,
        loadResDisputes: action.res,
      };
    case LOAD_RESOLVED_DISPUTES_ERROR:
      return {
        ...state,
        loadResDisputes: action.error,
      };
    default:
      return state;
  }
};
export default DisputeReducer;