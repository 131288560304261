import React from 'react'

const Unauthorized = props => {

  return (
    <>
        <p>Unauthorized to access ANZ transfer of value application. Please contact admin for access</p>
    </>
  )
}
export default Unauthorized
