import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  PaginationProvider,
  SizePerPageDropdownStandalone,
  PaginationTotalStandalone,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import { Form } from "react-bootstrap";

const { SearchBar } = Search;

// ------------- Table --------------
export class Table extends Component {
  constructor() {
    super();
    let selectRow = {
      mode: "radio",
      clickToSelect: false,
      headerColumnStyle: {
        backgroundColor: "transparent",
        width: "10px",
      },
    };
    this.state = { selectRow };
  }

  handlePagesizeChange() {
    let elt = document.querySelectorAll(
      'ul.dropdown-menu li.dropdown-item a[href="#"]'
    );
    console.log("************************** ");
    if (elt && elt.length) {
      elt.forEach(function (i) {
        i.removeAttribute("href");
        i.style.cursor = "pointer";
      });
    }
  }

  render() {
    const {
      keyField,
      data,
      columns,
      isPaginationEnable,
      onSelect,
    } = this.props;

    const options = {
      custom: true,
      totalSize: data.length,
      pageStartIndex: 1,
      alwaysShowAllBtns: true,
      withFirstAndLast: false,
      paginationTotalRenderer: () => (
        <span className="bootstrap-table-pagination-record-count">
          No of records per page
        </span>
      ),
      sizePerPageList: [25, 50, 100],
      onSizePerPageChange: () => {
        this.handlePagesizeChange();
      },
    };

    let prop = {};
    prop = { ...prop, noDataIndication: "No Records found !" };

    if (onSelect) {
      let { selectRow } = this.state;
      selectRow = { ...selectRow, onSelect };
      prop = { ...prop, selectRow };
    }

    if (isPaginationEnable) {
      prop = { ...prop, pagination: paginationFactory(options) };
    }

    return (
      <ToolkitProvider
        keyField={keyField}
        data={data}
        columns={columns}
        search={{
          afterSearch: (newResult) => this.handlePagesizeChange(),
        }}
      >
        {(props) => (
          <div>
            {/* {isSearchEnable ? (
              <>
                <SearchBar {...props.searchProps} placeholder="" />
              </>
            ) : (
              ""
            )} */}

            {isPaginationEnable ? (
              <PaginationProvider {...prop}>
                {({ paginationProps, paginationTableProps }) => (
                  <div>
                    <SearchBar {...props.searchProps} placeholder="" />
                    <BootstrapTable
                      {...prop}
                      {...props.baseProps}
                      {...paginationTableProps}
                      hover
                    />
                    <PaginationTotalStandalone {...paginationProps} />
                    <SizePerPageDropdownStandalone {...paginationProps} />
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                )}
              </PaginationProvider>
            ) : (
              <BootstrapTable {...prop} {...props.baseProps} hover />
            )}
          </div>
        )}
      </ToolkitProvider>
    );
  }
}

//  ------------ Section Header (HCP, Service) ----------------
export const SectionHeader = (props) => {
  const { label } = props;

  return (
    <div className="col-sm-6 col-md-6 col-lg-6">
      <div style={{ paddingBottom: "4%" }}>
        <span className="underline font-heading-custom">{label}</span>
      </div>
    </div>
  );
};

//  ------------ Search Input for Admin page----------------

export const SearchInputAdmin = (props) => {
  const { name, value, label, error, id, style, disabled } = props;
  const inputStyleValue = style;
  let isDisabled = disabled || false;

  return (
    <div className="col-sm-6 col-md-6 col-lg-6">
      <Form.Group
        className="row"
        style={{ display: inputStyleValue ? "none" : "" }}
        controlId={id}
      >
        <Form.Label
          className="col-sm-4 col-md-4 col-lg-4"
          style={{ textAlign: "right" }}
        >
          {label}
          <span className="required crt_mandate">*</span>
        </Form.Label>
        <Form.Control
          className="col-sm-8 col-md-8 col-lg-8"
          type="text"
          name={name}
          value={value}
          required
          placeholder=""
          onChange={props.onChange}
          onBlur={props.handleBlur}
          disabled={isDisabled}
        />
        {error ? <span className="error">{error}</span> : null}
      </Form.Group>
    </div>
  );
};

export const SearchInputAdminNumber = (props) => {
  const { name, value, label, error, id, style, disabled } = props;
  const inputStyleValue = style;
  let isDisabled = disabled || false;

  return (
    <div className="col-sm-6 col-md-6 col-lg-6">
      <Form.Group
        className="row"
        style={{ display: inputStyleValue ? "none" : "" }}
        controlId={id}
      >
        <Form.Label
          className="col-sm-4 col-md-4 col-lg-4"
          style={{ textAlign: "right" }}
        >
          {label}
          {/* <span className="required crt_mandate">*</span> */}
        </Form.Label>
        <Form.Control
          className="col-sm-8 col-md-8 col-lg-8"
          type="number"
          name={name}
          value={value}
          placeholder=""
          onChange={props.onChange}
          onBlur={props.handleBlur}
          disabled={isDisabled}
        />
        {error ? <span className="error">{error}</span> : null}
      </Form.Group>
    </div>
  );
};

//  ------------ Search Input for Admin page----------------

export const FormTextArea = (props) => {
  const { name, value, label, error, id, style } = props;
  const inputStyleValue = style;

  return (
    <div className="col-sm-6 col-md-6 col-lg-6">
      <Form.Group
        className="row"
        style={{ display: inputStyleValue ? "none" : "" }}
        controlId={id}
      >
        <Form.Label
          className="col-sm-4 col-md-4 col-lg-4"
          style={{ textAlign: "right" }}
        >
          {label}
          {/* <span className="required crt_mandate">*</span> */}
        </Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          className="col-sm-8 col-md-8 col-lg-8"
          name={name}
          value={value}
          placeholder=""
          readOnly
        />
        {error ? <span className="error">{error}</span> : null}
      </Form.Group>
    </div>
  );
};
