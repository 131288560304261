import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table, SectionHeader, SearchInputAdmin } from '../../common'
import { Formik } from 'formik'
import { Modal } from 'react-bootstrap'
import { useFormFields } from '../../../helpers/hooksFormInput'
import {
  loadAdminsList,
  registerAdminAction,
  updateAdminAction,
  deleteAdminAction
} from '../../../stores/actions/admin/AuthActions'

const UserManagement = () => {
  const dispatch = useDispatch()

  let columns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true
    },
    {
      dataField: 'role',
      text: 'User Role',
      sort: true
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true
    },
    {
      dataField: 'created_at',
      text: 'Created At',
      sort: true
    },
    {
      dataField: 'Edit',
      text: 'Edit',
      formatter: (cell, row) => (
        <span>
          <button
            onClick={() => handleDivChange('editAdmin', row)}
            style={{ background: 'none', border: 'none', marginRight: '10%' }}
          >
            <img
              src='/img/edit_product.png'
              alt='Edit Product'
              width='16'
              height='16'
            />
          </button>
          {/* <button 
          onClick={e => showDeleteAdminModal(e, row)}
          style={{ background: 'none', border: 'none' }}>
            <img
              src='/img/delete_product.png'
              alt='Delete Product'
              width='16'
              height='16'
            />
          </button> */}
        </span>
      )
    }
  ]

  const [admins, setAdmins] = useState([])
  const [showAddDiv, setShowAddDiv] = useState(false)
  const [formLabel, setFormLabel] = useState('')
  const [buttonName, setButtonName] = useState('')
  const [disable, setDisable] = useState(true)
  const [id, setId] = useState('')
  const [deleteAdminModal, setDeleteAdminModal] = useState(false)

  const [fields, setFields, handleFieldsChange] = useFormFields({
    name: '',
    email: '',
    status: 1
  })

  const loadAdmins = useSelector(state => state.adminAuth.loadAdmins)
  const registerAdmin = useSelector(state => state.adminAuth.registerAdmin)
  const updateAdmin = useSelector(state => state.adminAuth.updateAdmin)
  const deleteAdmin = useSelector(state => state.adminAuth.deleteAdmin)

  useEffect(() => {
    setAdmins(loadAdmins.response.data)
  }, [loadAdmins])

  useEffect(() => {
    dispatch(loadAdminsList())
  }, [dispatch, registerAdmin, updateAdmin, deleteAdmin])

  const handleDivChange = (type, el = null) => {
    let form_labal = 'Add Admin'
    let button_name = 'Add'
    if (type === 'addAdmin') {
      form_labal = 'Add Admin'
      button_name = 'Add'
      setId('')
      setFields({
        name: '',
        email: '',
        status: 1
      })
    } else if (type === 'editAdmin') {
      form_labal = 'Update Admin'
      button_name = 'Update'
      setId(el.id)
      setFields({
        name: el.name,
        email: el.email,
        status: el.status_id
      })
    }
    setShowAddDiv(true)
    setFormLabel(form_labal)
    setButtonName(button_name)
  }

  const handleRadioChange = e => {
    setFields({
      ...fields,
      status: parseInt(e.target.value)
    })
  }

  const validation = () => {
    const errors = {}
    errors.name = fields.name === '' ? 'admin name is required' : ''
    errors.email = fields.email === '' ? 'admin email is required' : ''
    errors.status = fields.status === '' ? 'admin status is required' : ''
    return errors
  }

  const addAndEditNewAdmin = e => {
    e.preventDefault()
    if (id === '') {
      dispatch(registerAdminAction(fields))
      handleCancelClick()
    } else {
      fields.id = id
      dispatch(updateAdminAction(fields))
      handleCancelClick()
    }
  }

  const handleCancelClick = () => {
    setShowAddDiv(false)
  }

  useEffect(() => {
    checkIfFormFieldsIsFilledProperly(fields)
  }, [fields])

  const checkIfFormFieldsIsFilledProperly = formFields => {
    for (let objects in formFields) {
      if (formFields[objects] === '' || formFields[objects] === null) {
        return setDisable(true)
      }
    }
    return setDisable(false)
  }

  // const showDeleteAdminModal = (e, row) => {
  //   setDeleteAdminModal(true)
  //   setId(row.id)
  //   setFields({
  //     name: row.name,
  //     email: row.email
  //   });
  // }

  const closeDeleteAdminModal = () => {
    setDeleteAdminModal(false)
  }

  const deactivateAdmin = () => {
    dispatch(deleteAdminAction(fields))
    setDeleteAdminModal(false)
  }

  return (
    <>
      <div className='intro_text'>
        <span>User Management &nbsp;</span>
        <img
          src='/img/arrow_back.png'
          alt='Back arrow'
          width='12'
          height='16'
        />
      </div>
      {!showAddDiv ? (
        <>
          <div className='font-custom form-margin-admin'>
            <div className='row'>
              <div className='col-lg-4 col-sm-4 col-md-4 offset-4'>
                <div className='col text-center'>
                  <button
                    type='button'
                    className='btn btn-primary btn-sm btn-custom'
                    onClick={() => handleDivChange('addAdmin')}
                  >
                    Add New Admin
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='font-custom form-margin-admin'>
            <Table
              keyField='id'
              data={admins}
              columns={columns}
              isPaginationEnable={true}
              wrapperClasses='table-responsive'
            />
          </div>
        </>
      ) : (
        <div className='font-custom form-margin-admin'>
          <div className='row'>
            <SectionHeader label={formLabel} />
          </div>
          <Formik initialValues={fields} validate={validation}>
            {(errors, touched, handleBlur) => (
              <form
                action=''
                method='post'
                name='addAdminForm'
                onSubmit={addAndEditNewAdmin}
              >
                <div className='row'>
                  <SearchInputAdmin
                    name='name'
                    id='name'
                    value={fields.name || ''}
                    label='Admin Name'
                    onChange={handleFieldsChange}
                    onBlur={handleBlur}
                    error={errors.name}
                  />
                  <SearchInputAdmin
                    name='email'
                    id='email'
                    value={fields.email || ''}
                    label='Admin Email'
                    onChange={handleFieldsChange}
                    onBlur={handleBlur}
                    error={errors.email}
                  />
                  <div className='col-sm-12 col-md-12 col-lg-12'>
                    <div className='col text-center'>
                      <div>
                        <input
                          type='radio'
                          value='1'
                          name='status'
                          checked={fields.status === 1}
                          onChange={handleRadioChange}
                        />
                        <label className='form-check-label' htmlFor='status'>
                          Active
                        </label>
                        <input
                          type='radio'
                          value='2'
                          name='status'
                          checked={fields.status === 2}
                          onChange={handleRadioChange}
                        />
                        <label className='form-check-label' htmlFor='status'>
                          InActive
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row addproductbutton'>
                  <button
                    disabled={disable}
                    type='submit'
                    name='Save'
                    className='btn btn-primary btn-custom'
                  >
                    {buttonName}
                  </button>
                  <button
                    type='button'
                    name='Cancel'
                    className='btn btn-primary btn-custom'
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      )}
      {/*delete admin modal view*/}
      <Modal
        id='adminModal'
        show={deleteAdminModal}
        onHide={closeDeleteAdminModal}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton />
        <Modal.Body style={{ textAlign: 'center' }}>
          <div
            style={{
              lineHeight: '30px',
              fontWeight: 'bold',
              fontSize: '16px'
            }}
          >
            <div style={{ color: 'red', fontSize: '20px' }}>
              Please confirm if you want to delete this admin.
            </div>
            <div>Admin Name : {fields.name}</div>
          </div>
          <div style={{ paddingTop: '2%', display: 'block' }}>
            <button
              onClick={deactivateAdmin}
              name='deleteAdminButton'
              className='btn btn-primary btn-custom'
            >
              Confirm
            </button>
            <button
              name='cancelButton'
              className='btn btn-primary btn-custom'
              onClick={closeDeleteAdminModal}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default UserManagement
