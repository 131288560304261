import {
    LOAD_TOVS,
    LOAD_TOVS_ERROR,
  } from "../../actiontypes/user/Tov";
  
  const initState = {
    loadTovs: {response:{data: []}},
  };
  
  const TovReducer = (state = initState, action) => {
    switch (action.type) {
      case LOAD_TOVS:
        return {
          ...state,
          loadTovs: action.res,
        };
      case LOAD_TOVS_ERROR:
        return {
          ...state,
          loadTovs: action.error,
        };
      default:
        return state;
    }
  };
  export default TovReducer;
  