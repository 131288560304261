import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import UserDashboard from "../components/user/pages/Dashboard";
import TOVReport from "../components/user/pages/TOVReport";
import RedirectPage from "../components/user/pages/RedirectPage";
import Template from "../components/user/partial/Template";
import Nav from "../components/user/partial/Nav";
import Topbar from "../components/layouts/user/Topbar";
import Footer from "../components/layouts/user/Footer";

export default function UserRoutes(props) {
  return (
    <>
      <Topbar />
      <div className="main">
        <Nav props={props} />
        <Template>
          <Switch>
            <Route
              exact
              path={`${props.match.path}/dashboard`}
              component={UserDashboard}
            />
            <Route
              exact
              path={props.match.path}
              render={(props) => (
                <Redirect to={{ pathname: `${props.match.path}/tov-report` }} />
              )}
            />
            <Route
              exact
              path={`${props.match.path}/tov-report`}
              component={TOVReport}
            />
            <Route
              exact
              path={`${props.match.path}/dispute-success`}
              component={RedirectPage}
            />
          </Switch>
        </Template>
        <Footer />
      </div>
    </>
  );
}
