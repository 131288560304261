import React, { useState, useEffect } from 'react'
import jwt_decode from 'jwt-decode'
import { Modal } from 'react-bootstrap'
import { Table } from '../../common'
import { useDispatch, useSelector } from 'react-redux'
import { loadTovReports } from '../../../stores/actions/user/TovActions'
import {
  raiseDisputeAction,
  loadDisputeReports
} from '../../../stores/actions/user/DisputeActions'

const TOVReport = props => {
  const dispatch = useDispatch()

  const d = new Date()
  const crntMonth = d.getMonth()
  const crntYear = d.getFullYear()
  const nxtYear = crntYear + 1
  var paymentDate
  if (crntMonth >= 4 && crntMonth <= 9) {
    paymentDate = '28 Feb ' + nxtYear
  } else if (crntMonth < 4) {
    paymentDate = '31 Aug ' + crntYear
  } else {
    paymentDate = '31 Aug ' + nxtYear
  }

  const [disputeRow, setDisputeRow] = useState({})
  const [raiseDisputeModal, setRaiseDisputeModal] = useState(false)

  const createDispute = useSelector(state => state.userDispute.createDispute)

  const showRaiseDisputeModal = (e, row) => {
    let ProductDetails = row
    setRaiseDisputeModal(true)
    setDisputeRow(ProductDetails)
  }

  const closeRaiseDisputeModal = () => {
    setRaiseDisputeModal(false)
    setDisputeRow({})
  }

  const raiseDispute = () => {
    dispatch(raiseDisputeAction(disputeRow, props))
    setRaiseDisputeModal(false)
  }

  let columns = [
    {
      dataField: 'event_date',
      text: 'Event Date',
      sort: true
    },
    {
      dataField: 'event_name',
      text: 'Event Name',
      sort: true
    },
    {
      dataField: 'address_city',
      text: 'City',
      sort: true
    },
    {
      dataField: 'hcp_paidto',
      text: 'Paid To',
      sort: true
    },
    {
      dataField: 'tov_registration',
      text: 'Registration',
      sort: true
    },
    {
      dataField: 'tov_transport',
      text: 'Transport',
      sort: true
    },
    {
      dataField: 'tov_hotel',
      text: 'Hotel',
      sort: true
    },
    {
      dataField: 'tov_honorarium',
      text: 'Honorarium',
      sort: true
    },
    {
      dataField: 'Dispute',
      text: 'Dispute',
      sort: true,
      formatter: (cell, row) => (
        <span>
          <button
            onClick={e => showRaiseDisputeModal(e, row)}
            type='button'
            className='btn btn-outline-primary btn-sm'
          >
            Raise Dispute
          </button>
        </span>
      )
    }
  ]

  let columns2 = [
    {
      dataField: 'id',
      text: 'Dispute Id',
      sort: true
    },
    {
      dataField: 'event_id',
      text: 'Event Id',
      sort: true
    },
    {
      dataField: 'event_name',
      text: 'Event Name',
      sort: true
    },
    {
      dataField: 'hcp_participation_id',
      text: 'Participation Id',
      sort: true
    },
    {
      dataField: 'hcp_name',
      text: 'HCP Name',
      sort: true
    },
    {
      dataField: 'hcp_paidto',
      text: 'Paid To',
      sort: true
    },
    {
      dataField: 'created_at',
      text: 'Created at',
      sort: true
    },
    {
      dataField: '',
      text: 'Status',
      sort: true,
      formatter: (cell, row) => (
        <span className='badge badge-pill badge-primary'>{row.status}</span>
      )
    }
  ]

  const [tovs, setTovs] = useState([])
  const [disputes, setDisputes] = useState([])

  const token = localStorage.getItem('user-token')
  const auth = jwt_decode(token)

  const loadTovs = useSelector(state => state.userTov.loadTovs)
  const loadDisputes = useSelector(state => state.userDispute.loadDisputes)

  useEffect(() => {
    setTovs(loadTovs.response.data)
  }, [loadTovs])

  useEffect(() => {
    setDisputes(loadDisputes.response.data)
  }, [loadDisputes])

  useEffect(() => {
    const payload = {
      tov_date_range: null
    }
    dispatch(loadTovReports(payload))
    dispatch(loadDisputeReports())
  }, [dispatch, createDispute])

  return (
    <>
      <div>
        <p className='intro_sub_text'>
          <b> Dear Dr. {auth.user.name}</b>,
        </p>
        <p className='intro_sub_text text-justify'>
          Under the Medicines Australia Code of Conduct transparency provisions,
          Lilly is required to report all payments and transfers of value made
          to you or on your behalf and individually attribute to you for the
          activities/events in a six-month reporting period. In accordance with
          these reporting requirements, your name, details of services provided
          by you and all payments and transfers of value made to you or on your
          behalf are published for three years from the publication date in the
          Medicines Australia Central Reporting System (CRS), which is a
          publicly available, consolidated database of all reportable payments
          made by each of Medicines Australia's members.
        </p>
        <p className='intro_sub_text text-justify'>
          To facilitate this reporting, Lilly may disclose your personal
          information to:
        </p>
        <ul className='ml-5'>
          <li className='font-paragraph'>
            Medicines Australia or Pacific Commerce Pty Ltd (as the operator of
            the CRS) for the purpose of operating, maintaining, and including
            information in the CRS; and
          </li>
          <li className='font-paragraph'>
            Medicines Australia to record and publish reportable payments, carry
            out analytics (including to detect trends in reportable payments)
            for internal use by Medicines Australia and in discussion with its
            members, and to aggregate information to derive insights.
          </li>
        </ul>
        <p className='intro_sub_text text-justify'>
          For the most recent reporting period, the following payments and
          transfers of value have been documented for you and will be published
          and disclosed in accordance with the above. This publication will
          occur on {paymentDate}. Please review the below information and click
          the “Raise Dispute” button if you believe the information is
          incorrect. If you do not raise a dispute within the timeframe, details
          will be sent for publishing on the Medicine’s Australia website.
        </p>
      </div>
      <div className='intro_text'>
        <span>Transfer Of Value Details &nbsp;</span>
        <img
          src='/img/arrow_back.png'
          alt='Back arrow'
          width='12'
          height='16'
        />
      </div>
      <div className='font-custom form-margin-admin'>
        <Table
          keyField='id'
          data={tovs}
          columns={columns}
          isPaginationEnable={true}
          wrapperClasses='table-responsive'
        />
        {/*Raise dispute modal view*/}
        <Modal
          id='disputeModal'
          show={raiseDisputeModal}
          onHide={closeRaiseDisputeModal}
          backdrop='static'
          keyboard={false}
        >
          <Modal.Header closeButton />
          <Modal.Body style={{ textAlign: 'center' }}>
            <div
              style={{
                lineHeight: '30px',
                fontWeight: 'bold',
                fontSize: '16px'
              }}
            >
              <div style={{ color: 'red', fontSize: '20px' }}>
                Please confirm if you want to raise dispute for this event.
              </div>
              <div>Event Name : {disputeRow.event_name}</div>
              <div>Event Date : {disputeRow.event_date}</div>
            </div>
            <div style={{ paddingTop: '2%', display: 'block' }}>
              <button
                onClick={raiseDispute}
                name='raiseDisputeButton'
                className='btn btn-primary btn-custom'
              >
                Confirm
              </button>
              <button
                name='cancelButton'
                className='btn btn-primary btn-custom'
                onClick={closeRaiseDisputeModal}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <br />
      <br />
      <div className='intro_text'>
        <span>Previously Raised Disputes &nbsp;</span>
        <img
          src='/img/arrow_back.png'
          alt='Back arrow'
          width='12'
          height='16'
        />
      </div>
      <div className='font-custom form-margin-admin'>
        <Table
          keyField='id'
          data={disputes}
          columns={columns2}
          isPaginationEnable={true}
          wrapperClasses='table-responsive'
        />
      </div>
    </>
  )
}

export default TOVReport