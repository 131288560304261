import { combineReducers } from 'redux'
import AdminAuthReducer from './admin/AuthReducer'
import AdminTovReducer from './admin/TovReducer'
import AdminDisputeReducer from './admin/DisputeReducer'
import UserAuthReducer from './user/AuthReducer'
import UserTovReducer from './user/TovReducer'
import UserDisputeReducer from './user/DisputeReducer'

const RootReducer = combineReducers({
  adminAuth: AdminAuthReducer,
  adminTov: AdminTovReducer,
  adminDispute: AdminDisputeReducer,
  userAuth: UserAuthReducer,
  userTov: UserTovReducer,
  userDispute: UserDisputeReducer
})

export default RootReducer
